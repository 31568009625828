<template>
  <DgImage v-if="widget.data" :data="widget.data" />
</template>
<script>
import MixinFixWidget from "@/mixins/fixWidget";
import { DgImage } from "@engenharia/dashgoo-widgets";

export default {
  name: "RenderWidgetImage",
  mixins: [MixinFixWidget],
  components: {
    DgImage,
  },
  mounted() {
    this.observeWidgetHeight();
  },
};
</script>
